import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'
import { Wrapper } from '../components/styles/grid'
import { PageHeading, PostHeading, FooterDark, ButtonDark } from '../components/styles/elements'

const PhotoContainer = styled.div`
    grid-column: 2 / 17;
    height: 100%;
    overflow-x: scroll;
`

const PhotoScroll = styled.div`
    width: 3000px;
    height: 100%;
    background-color: rgba(255,0,255,0.2);
`

const Image = styled.img`
    display: block;
    margin: 0 5px 0 0;
    padding: 0;
    height: 100%;
    width: auto;
`

const PhotographyPage = () => (
    <LayoutDark>
        <Seo title="Photography" keywords={[`gatsby`, `application`, `react`, `blog`, `web developer`, `photographer`]} />

        <Wrapper as='main'>
            <PostHeading>Photography</PostHeading>
            <PhotoContainer>
                <PhotoScroll>
                    <Image src="https://placeimg.com/640/480/any" />
                    <Image src="https://placeimg.com/640/480/any" />
                </PhotoScroll>
            </PhotoContainer>
        </Wrapper>
    </LayoutDark>
);

export default PhotographyPage
