import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import { GlobalStyle, Container } from './styles/elements'
import './styles/normalise.css'

if (typeof window !== `undefined`) {
  const WebFont = require('webfontloader')

  WebFont.load({
    google: {
      families: ['Libre Baskerville:400,700', 'PT Sans:400,700']
    },
    classes: false
  })
}

const Layout = ({ children }) => (
    <>
      <GlobalStyle />
      
      <Header />
      
      <Container>
        {children}
      </Container>
    </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
